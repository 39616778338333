import React from 'react'
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import { Link } from 'gatsby'

const BlogSection = () => {
    const data = useStaticQuery(graphql`
    query BlogQuery {
        allBlogSectionJson {
          edges {
            node {
              alt
              button
              link
              description
              name
              img {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `)

    const getBlogs = (data) => {
        const BlogArray = []
        data.allBlogSectionJson.edges.forEach((element, index) => {
            BlogArray.push(
                <BlogCard data-sal="slide-up" data-sal-easing="ease" key={index}>
                    <BlogImg
                        alt={element.node.alt}
                        fluid={element.node.img.childImageSharp.fluid}>
                    </BlogImg>
                    <BlogInfo>
                        <BlogHeading>{element.node.name}</BlogHeading>
                        <BlogP>{element.node.description}</BlogP>
                        <Button to={element.node.link} css={`font-size: 20px;`}>{element.node.button}</Button>
                    </BlogInfo>
                </BlogCard>
            )
        });
        return BlogArray
    }

    return (
        <BlogsContainer>
            <BlogsHeader css={`color: #fff`}>Our Blog</BlogsHeader>
            <BlogsP css={`color: #fff`}>Want to understand more on the future of Health Technology?</BlogsP>
            <BlogsP css={`color: #fff`}>Check out our blog</BlogsP>
            <BlogWrapper >{getBlogs(data)}</BlogWrapper>

        </BlogsContainer>

    )
}

export default BlogSection

const BlogsContainer = styled.div`
    min-height: 100vh;
    padding: 3rem calc((100vw - 1300px) / 2);
    background: linear-gradient(to bottom, #1C333A 10%,#1B353C 50%,#1D3238 70%, #187E9C 100%);
    color: #000;
`

const BlogsHeader = styled.h2`
    text-align: center;
    font-size: clamp(1.3rem,3vw, 2rem);
    margin-bottom: 3rem;    
`

const BlogsP = styled.p`
    font-size: clamp(1rem,2vw, 1.25rem);
    text-align: center;
    margin-bottom: 2rem;    
`

const BlogWrapper = styled.div`
    display:grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    justify-items: center;
    padding: 0 2rem;

    @media screen and (min-width: 800px) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        }

    @media screen and (min-width: 1000px) {
        grid-template-columns: repeat(3,1fr);
        grid-gap: 20px;
        }
`
const BlogCard = styled.div`
    line-height: 2;
    width: 100%;
    height: auto;
    position: relative;
    border-radius: 10px;
    background: #fff;    
    transition: 0.2s ease;

    :hover {
        transform: scale(1.05)
    }
    
`

const BlogInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
    // position: absolute; 
    // top: 150px;
    
`

const BlogImg = styled(Img)`
    max-width: 80%;      
    border-radius: 10px;
    position: absolute;
    top: 10px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 1200px) {
        width: 100%;  
        }
`

const BlogHeading = styled.h3`
    text-align: center;
    font-size: clamp(1.5rem,3vw, 1.8rem);
    margin-bottom: 1rem; 
    margin-top: 1rem;
`

const BlogP = styled.p`
    font-size: clamp(1rem,2vw, 1rem);
    text-align: center;
    margin-bottom: 2rem;
`
const Button = styled(Link)`
    background-color: #71C4DE;
    white-space: nowrap;
    padding: 10px 60px;
    color: white;
    font-size:  20px;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    transitions: 0.3s !important;
    border-radius: 10px;
    margin-bottom: 1rem;

    &:hover {
        text-decoration-line: none;
    }
`

