import React from 'react'
import styled from 'styled-components';
import { Button } from "./Button";
// import { Link } from 'gatsby'
// import { BsChevronDoubleDown } from 'react-icons/bs';
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'


export default function Hero() {
    const data = useStaticQuery(graphql`
        query HomePageQuery {
            allLandingPagesJson {
            edges {
                node {
                alt11
                img11 {
                    childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                    }
                }
                }
            }
        }
        }
    `)

    const getImage = (data) => {
        const imageArray = []
        data.allLandingPagesJson.edges.forEach((element, index) => {
            imageArray.push(
                <div key={index}>
                    <Image
                        alt={element.node.alt11}
                        fluid={element.node.img11.childImageSharp.fluid}>
                    </Image>

                </div>
            )
        })
        return imageArray
    }
    return (
        <HeroContainer>
            <HeroContent>
                <HeroItems>
                    <HeroH1>Strengthening Health Systems</HeroH1>
                    <HeroP>Our intelligent technology solutions:</HeroP>
                    <HeroUl>
                        <Heroli>Improve patient experience</Heroli>
                        <Heroli>Improve quality of care</Heroli>
                        <Heroli>Improve health equity</Heroli>
                        <Heroli>Reduce cost</Heroli>
                        <Button primary='true' big='true' to='/request/' >Get Proposal </Button>
                    </HeroUl>
                    {/* <HeroBtn>
                        <ButtonClear to='/contact/' >Contact Us </ButtonClear>
                    </HeroBtn>
                    <Arrow /> */}
                </HeroItems>
                <HeroImage>{getImage(data)}</HeroImage>
            </HeroContent>
        </HeroContainer>
    )
}

const HeroContainer = styled.div`
    height: 100%;
    width: 100%;
    margin:0px;
    padding:0px;
`

const HeroContent = styled.div`
    display:flex;
    height: 100%;
    margin-top: 5rem;
    align-items: center;
    flex-wrap: wrap-reverse;
    
`
const HeroImage = styled.div`
    flex: 1 1 5rem;
`
const Image = styled(Img)`
   max-width: 600px;
   margin-left: auto;
   margin-right: auto;
   min-width: 300px;
`

const HeroItems = styled.div`
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
    text-align: center;
    //height: 100vh;
    max-height: 100%;
    padding: 1rem;
    color:#000;
    line-height: 2;

    @media screen and (min-width: 400px) {
        text-align: justify;
    }
`

const HeroH1 = styled.h1`
    font-size: clamp(1.5rem,3vw, 3rem);
    margin-bottom: 1.5rem;
    padding: 0 0.5rem;
`

const HeroP = styled.p`
    font-size: clamp(1.2rem,2vw, 2rem); 
    padding: 0 0.5rem;   
`

const HeroUl = styled.ul`
    font-size: clamp(1rem,1.5vw, 1.3rem);
    padding: 0 0.5rem;
`

const Heroli = styled.li`
    list-style: none;
`
// const HeroBtn = styled.div`
//     //display: flex;
//     //flex-direction: column;
//     width: 100%;


//     // @media screen and (min-width: 800px) {
//     //     display: flex;
//     //     flex-direction: row;
//     //     width: 100%;
//     //     justify-content: space-between;
//     //     }
// `

// const ButtonClear = styled(Link)`
//     background-color: Transparent;
//     white-space: nowrap;
//     padding: 10px 60px;
//     color: white;
//     font-size:  20px;
//     outline: none;
//     border: 2px solid #EF6F6C;
//     cursor: pointer;
//     text-decoration: none;
//     transitions: 0.3s !important;
//     border-radius: 10px;
//     margin-bottom: 1rem;

//     &:hover {
//         text-decoration-line: none;
//     }
// `

// const Arrow = styled(BsChevronDoubleDown)`
//   font-size: 1.8rem;
//   color: #fff;
//   margin-left: auto;
//   margin-right: auto;


// `