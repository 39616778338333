import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import { Button } from '../components/Button'
import Hero from '../components/Hero'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Services from '../components/Services'
import Usecase from '../components/Usecase'
import Whyus from '../components/Whyus'
import BlogSection from '../components/BlogSection'
import Proposal from '../components/Proposal'



const IndexPage = () => (
  <Layout>
    <SEO title="Software company in kenya developing Health information management system"
      description="We are a health software company focusing on improving healthcare businesses through software solutions and artificial intelligence. Call us 0792 640 973"
      keywords="web design kenya, kenya web design, digital marketing in kenya, software companies in kenya, software company in kenya, software developers in kenya, software development in kenya,data analytics, it company in kenya, software kenya, website design in kenya, website design kenya,
                kenya health information system, data science kenya, mhealth, digital health in kenya, Web development in Nairobi Kenya, web design in Nairobi Kenya, hospital management system kenya, web hosting, erp, hospital management system, health system management,
                health system management, online marketing kenya, dashboard, mobile apps, Hospital management software, erp software, hmis kenya, kenya emr, digital healthcare solutions, artificial intelligence companies in kenya, artificial intelligence kenya, ai companies in kenya, data analysis companies in kenya,
                data science companies in kenya, predictive analytics kenya, data analytics kenya, digital marketing agencies in kenya, B2B pharmaceutical marketplace "
      image="https://www.tripleaimsoftware.com/static/db634d2534192261c49c492718302df2/14b42/healthcare.jpg"
      siteUrl="https://www.tripleaimsoftware.com/"
      canonical="https://www.tripleaimsoftware.com/">
    </SEO>
    <Hero />
    <Usecase />
    <Services />
    <Whyus />
    <BlogSection />
    <SubscribeContainer>
      <ContainerP1>Subscribe</ContainerP1>
      <ContainerP2>Enter Your Email Below </ContainerP2>
      <form name="subscribe" method="POST" data-netlify="true" netlify-honeypot="bot-field">
        <input type="hidden" name="form-name" value="subscribe" />
        <FormWrap>
          <label htmlFor="email">
            <input type="email" id="email" name="email" />
          </label>
          <Button as="button" type="submit" primary="true" css={`width: 100%; height: 50px;`}>Free Newsletter</Button>
        </FormWrap>
      </form>
    </SubscribeContainer>
    <Proposal />
  </Layout>
)

export default IndexPage

const SubscribeContainer = styled.div`
    background: #fff;
    margin-top: 2rem;
    margin-bottom: 1rem;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 1000px) {
        max-width: 40%;
    }
`
const ContainerP1 = styled.p`
    font-size: clamp(1rem,2vw, 1.25rem);
    text-align: center;
    padding: 2rem 1rem 0rem 1rem;
    
`
const ContainerP2 = styled.p`
    font-size: clamp(1rem,2vw, 1.25rem);
    text-align: center;
`
const FormWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
   

 input {
     padding: 1rem 1,5rem;
     outline: none;
     width: 100%;
     height: 50px;
     border-radius: 20px;
     border: 2px solid #71C4DE;
     margin-bottom: 1rem;
     text-align: center;
 }
`
