import React from 'react'
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'

const Whyus = () => {
    const data = useStaticQuery(graphql`
    query WhyusQuery {
        allWhyusDataJson {
          edges {
            node {
              alt
              description
              name
              img {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `)

    const getWhyus = (data) => {
        const whyusArray = []
        data.allWhyusDataJson.edges.forEach((element, index) => {
            whyusArray.push(
                <WhyusCard data-sal="slide-up" data-sal-easing="ease" key={index}>
                    <WhyusImg
                        alt={element.node.alt}
                        fluid={element.node.img.childImageSharp.fluid}>
                    </WhyusImg>
                    <WhyusInfo>
                        <WhyusHeading>{element.node.name}</WhyusHeading>
                        <WhyusP>{element.node.description}</WhyusP>
                    </WhyusInfo>
                </WhyusCard>
            )
        });
        return whyusArray
    }


    return (
        <WhyusContainer>
            <WhyusHeader>Why Us</WhyusHeader>
            <WhyusWrapper >{getWhyus(data)}</WhyusWrapper>
        </WhyusContainer>
    )
}

export default Whyus


const WhyusContainer = styled.div`
    min-height: 100vh;
    padding: 3rem calc((100vw - 1300px) / 2);
    background: radial-gradient(farthest-side at 50% 50%,#71C4DE,#7FDBF7,#5EA3B8);
    color: #000;
`

const WhyusHeader = styled.h2`
    text-align: center;
    font-size: clamp(1.3rem,3vw, 2rem);
    margin-bottom: 3rem; 
`

const WhyusWrapper = styled.div`
    display:grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    padding: 0 2rem;
    

    @media screen and (min-width: 700px) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        align-content: center;
      }

    @media screen and (min-width: 1200px) {
        grid-template-columns: repeat(3,1fr);
        grid-gap: 20px;
        }
`

const WhyusCard = styled.div`
    line-height: 2;
    width: 100%;
    height: 450px;
    position: relative;
    border-radius: 10px;
    transition: 0.2s ease;
    background: rgb(229, 229, 229, 0.0);
    border-style: solid;
    border-color: #000;

    :hover {
        transform: scale(1.05)
    }
    
`

const WhyusImg = styled(Img)`
    width: 20%;  
    max-height: 100%;   
    border-radius: 10px;
    top: 20px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
`

const WhyusInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
    position: absolute; 
    top: 150px;
`

const WhyusHeading = styled.h3`
    text-align: center;
    font-size: clamp(1.2rem,3vw, 1.5rem);
    margin-bottom: 1rem; 
`

const WhyusP = styled.p`
    font-size: clamp(1rem,2vw, 1rem);
    text-align: center;
    margin-bottom: 2rem;
`

