import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components';
import Img from 'gatsby-image'
//import Chart from '../images/chart.png'
import { Link } from 'gatsby'

const Usecase = () => {
    const data = useStaticQuery(graphql`
    query UsecaseQuery {
        allUsecaseDataJson {
          edges {
            node {
              alt
              description
              name
              link
              linkText
              img {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `)

    const getUsecase = (data) => {
        const usecaseArray = []
        data.allUsecaseDataJson.edges.forEach((element, index) => {
            usecaseArray.push(
                <UsecaseCard data-sal="slide-up" data-sal-easing="ease" key={index}>
                    <UsecaseImg
                        alt={element.node.alt}
                        fluid={element.node.img.childImageSharp.fluid}>
                    </UsecaseImg>
                    <UsecaseInfo>
                        <UsecaseHeading>{element.node.name}</UsecaseHeading>
                        <UsecaseP>{element.node.description}</UsecaseP>
                        <UsecaseP2 to={element.node.link}>{element.node.linkText}</UsecaseP2>
                    </UsecaseInfo>
                </UsecaseCard>
            )
        });
        return usecaseArray
    }

    return (
        <UsecaseContainer>
            <UsecasesHeading>Products</UsecasesHeading>
            <UsecaseWrapper >{getUsecase(data)}</UsecaseWrapper>
        </UsecaseContainer>
    )
}

export default Usecase


const UsecaseContainer = styled.div`
    min-height: 100%;
    width:100%;
    padding: 3rem calc((100vw - 1300px) / 2);
    color: #000;
`

const UsecasesHeading = styled.h2`
    text-align: center;
    font-size: clamp(1.3rem,3vw, 2rem);
    margin-bottom: 3rem;   
`

const UsecaseWrapper = styled.div`
    display:flex;
    flex-wrap:wrap;
    justify-content: space-around;
   
`

const UsecaseCard = styled.div`
    line-height: 2;
    width: 80%;
    min-height:100%;
    border-radius: 10px;
    border-style: solid;
    border-color: #7FDBF7;
    background: rgb(229, 229, 229, 0.2); 
    transition: 0.2s ease;
    display: flex;
    flex-direction: column;
    margin-bottom:1rem;
    

    @media screen and (min-width: 700px) {
        flex-direction: row;
    }
`

const UsecaseImg = styled(Img)`
    width: 100%;   
   
    @media screen and (min-width: 700px) {
        min-width:300px;  
    }
`

const UsecaseInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem 1rem;
`

const UsecaseHeading = styled.h3`
    text-align: left;
    font-size: clamp(1rem,2vw, 1.2rem);
    margin-bottom: 1rem; 
    margin-top: 1rem;
    color:#71C4DE;      
`

const UsecaseP = styled.p`
    font-size: clamp(0.8rem,1vw, 1rem);
    text-align: left;
    margin-bottom: 2rem;
`

const UsecaseP2 = styled(Link)`
    margin-left: auto;
    margin-bottom: 2rem;
    color:#226BE9;;
`
