import React from 'react'
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
//import { Link } from 'gatsby'
import { useState } from 'react'

const Services = () => {
    const data = useStaticQuery(graphql`
    query ServicesQuery {
        allServiceDataJson {
          edges {
            node {
              alt
              button
              link
              description
              description1
              name
              img {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `)
    const [readMore, setReadMore] = useState(false)
    const [readMore1, setReadMore1] = useState(false)
    const [readMore2, setReadMore2] = useState(false)
    const linkName = readMore ? 'Read Less <<' : 'Read More >>'
    const linkName1 = readMore1 ? 'Read Less <<' : 'Read More >>'
    const linkName2 = readMore2 ? 'Read Less <<' : 'Read More >>'
    const getServices = (data) => {
        const serviceArray = []
        // data.allServiceDataJson.edges.forEach((element, index) => {
        //     serviceArray.push(
        //         <ServiceCard data-sal="slide-up" data-sal-easing="ease" key={index}>
        //             <ServiceImg
        //                 alt={element.node.alt}
        //                 fluid={element.node.img.childImageSharp.fluid}>
        //             </ServiceImg>
        //             <ServiceInfo>
        //                 <ServiceHeading>{element.node.name}</ServiceHeading>
        //                 <ServiceP>{element.node.description}</ServiceP>
        //                 <ServiceP css={`color:blue;`} onClick={() => { setReadMore(!readMore) }}>{linkName}</ServiceP>
        //                 <ServiceP>{readMore && element.node.description1}</ServiceP>
        //                 {/* <Button to={element.node.link} css={`font-size: 20px;`}>{element.node.button}</Button> */}
        //             </ServiceInfo>
        //         </ServiceCard>
        //     )
        // });
        const element = data.allServiceDataJson.edges
        serviceArray.push(
            <ServiceCard data-sal="slide-up" data-sal-easing="ease" key={element[0].node.alt} >
                <ServiceImg
                    alt={element[0].node.alt}
                    fluid={element[0].node.img.childImageSharp.fluid}>
                </ServiceImg>
                <ServiceInfo>
                    <ServiceHeading>{element[0].node.name}</ServiceHeading>
                    <ServiceP>{element[0].node.description}</ServiceP>
                    <ServiceP css={`color:blue;`} onClick={() => { setReadMore(!readMore) }}>{linkName}</ServiceP>
                    <ServiceP>{readMore && element[0].node.description1}</ServiceP>
                    {/* <Button to={element.node.link} css={`font-size: 20px;`}>{element.node.button}</Button> */}
                </ServiceInfo>
            </ServiceCard>,
            <ServiceCard data-sal="slide-up" data-sal-easing="ease" key={element[1].node.alt}>
                <ServiceImg
                    alt={element[1].node.alt}
                    fluid={element[1].node.img.childImageSharp.fluid}>
                </ServiceImg>
                <ServiceInfo>
                    <ServiceHeading>{element[1].node.name}</ServiceHeading>
                    <ServiceP>{element[1].node.description}</ServiceP>
                    <ServiceP css={`color:blue;`} onClick={() => { setReadMore1(!readMore1) }}>{linkName1}</ServiceP>
                    <ServiceP>{readMore1 && element[1].node.description1}</ServiceP>
                    {/* <Button to={element.node.link} css={`font-size: 20px;`}>{element.node.button}</Button> */}
                </ServiceInfo>
            </ServiceCard>,
            <ServiceCard data-sal="slide-up" data-sal-easing="ease" key={element[2].node.alt} >
                <ServiceImg
                    alt={element[2].node.alt}
                    fluid={element[2].node.img.childImageSharp.fluid}>
                </ServiceImg>
                <ServiceInfo>
                    <ServiceHeading>{element[2].node.name}</ServiceHeading>
                    <ServiceP>{element[2].node.description}</ServiceP>
                    <ServiceP css={`color:blue;`} onClick={() => { setReadMore2(!readMore2) }}>{linkName2}</ServiceP>
                    <ServiceP>{readMore2 && element[2].node.description1}</ServiceP>
                    {/* <Button to={element.node.link} css={`font-size: 20px;`}>{element.node.button}</Button> */}
                </ServiceInfo>
            </ServiceCard>
        )

        return serviceArray
    }

    return (
        <ServicesContainer>
            <ServicesHeader>How we do it</ServicesHeader>
            <ServicesP>We leverage data to build models that predict the future to help you in decision making</ServicesP>
            <ServicesP>We design, build and maintain websites, custom software application, digital marketing.</ServicesP>
            <ServiceWrapper > {getServices(data)} </ServiceWrapper>
        </ServicesContainer>

    )
}

export default Services

const ServicesContainer = styled.div`
    min-height: 100vh;
    padding: 3rem calc((100vw - 1300px) / 2);
    background: #E5E5E5;
    color: #000;
`

const ServicesHeader = styled.h2`
    text-align: center;
    font-size: clamp(1.3rem,3vw, 2rem);
    margin-bottom: 3rem;    
`

const ServicesP = styled.p`
    font-size: clamp(1rem,2vw, 1.25rem);
    text-align: center;
    margin-bottom: 2rem;    
`

const ServiceWrapper = styled.div`
    display:grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    justify-items: center;
    padding: 0 2rem;
    

    @media screen and (min-width: 800px) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        }

    @media screen and (min-width: 1200px) {
        grid-template-columns: repeat(3,1fr);
        grid-gap: 20px;
        }
`
const ServiceCard = styled.div`
    line-height: 2;
    width: 100%;
    min-height: 500px;
    position: relative;
    border-radius: 10px;
    background: #fff; 
    overflow-x: hidden;
    overflow-y: scroll;
  
    ::-webkit-scrollbar {
        width: 0;
    }
    
    :hover {
        transform: scale(1.05)
    }
        
`

const ServiceInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
    position: absolute; 
    top: 150px;
    width:100%;
`

const ServiceImg = styled(Img)`
    width: 20%;      
    border-radius: 10px;
    position: absolute;
    top: 20px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 1200px) {
        width: 30%;  
        }
`

const ServiceHeading = styled.h3`
    text-align: center;
    font-size: clamp(1.2rem,3vw, 1.5rem);
    margin-bottom: 1rem; 
`

const ServiceP = styled.p`
    font-size: clamp(1rem,2vw, 1rem);
    text-align: center;
    margin-bottom: 2rem;
`
// const Button = styled(Link)`
//     background-color: #71C4DE;
//     white-space: nowrap;
//     padding: 10px 60px;
//     color: white;
//     font-size:  20px;
//     outline: none;
//     cursor: pointer;
//     text-decoration: none;
//     transitions: 0.3s !important;
//     border-radius: 10px;
//     margin-bottom: 1rem;

//     &:hover {
//         text-decoration-line: none;
//     }
// `

